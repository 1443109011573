<template lang="html">
  <div>
    <DashboardStatusLine v-if="status === 'empty'" warning>
      <IxRes>dlgCert.status.obligatory.empty</IxRes>
    </DashboardStatusLine>
    <DashboardStatusLine v-else-if="status === 'started'" warning>
      <IxRes>dlgCert.status.obligatory.started</IxRes>
    </DashboardStatusLine>
    <DashboardStatusLine v-else-if="status === 'finished'">
      <IxRes>dlgCert.status.obligatory.finished</IxRes>
    </DashboardStatusLine>
  </div>
</template>

<script>
import DashboardStatusLine from './DashboardStatusLine'

export default {
  components: {
    DashboardStatusLine
  },
  props: {
    status: String
  }
}
</script>

<style lang="scss" scoped>
</style>
