<template lang="html">
  <PillarOverviewGrid v-if="data" :year="year">
    <PillarOverviewTile
      pillar="ecology"
      route="agriculture"
    >
      <CompletedStatusLine :status="data.agricultureState" />
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="ecology"
      route="grassland"
    >
      <ObligatoryStateLine :status="data.grasslandState" />
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="ecology"
      route="plantProtection"
      :finished="data.plantProtection.state == 'finished'" @finish="onFinish('plantProtection')"
    >
      <ObligatoryStateLine v-if="!data.finished.plantProtection" :status="data.plantProtection.state" />
      <PillarOverviewStatusLine v-else>
        <IxRes>dlgCert.status.plantProtection.manualComplete</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="ecology"
      route="soilHealth"
      :finished="data.soilHealthState == 'finished'" @finish="onFinish('soilHealth')"
    >
      <ObligatoryStateLine v-if="!data.finished.soilHealth" :status="data.soilHealthState" />
      <PillarOverviewStatusLine v-else>
        <IxRes>dlgCert.status.soilHealth.manualComplete</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="ecology"
      route="erosion"
    >
      <CompletedStatusLine :status="data.erosionState" />
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="ecology"
      route="water"
      :finished="data.waterState == 'finished'" @finish="onFinish('water')"
    >
      <ObligatoryStateLine v-if="!data.finished.water" :status="data.waterState" />
      <PillarOverviewStatusLine v-else>
        <IxRes>dlgCert.status.water.manualComplete</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>
  </PillarOverviewGrid>
  <div v-else class="loading-indicator-block">
    <FaIcon :icon="loadingIcon" size="3x" spin />
  </div>
</template>

<script>
import PillarOverviewGrid from './widgets/PillarOverviewGrid'
import PillarOverviewTile from './widgets/PillarOverviewTile'
import PillarOverviewStatusLine from './widgets/PillarOverviewStatusLine'
import CompletedStatusLine from './widgets/CompletedStatusLine'
import ObligatoryStateLine from './widgets/ObligatoryStateLine'
import {FontAwesomeIcon as FaIcon} from '@fortawesome/vue-fontawesome'
import {faSync} from '@fortawesome/free-solid-svg-icons'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    PillarOverviewGrid,
    PillarOverviewTile,
    PillarOverviewStatusLine,
    CompletedStatusLine,
    ObligatoryStateLine,
    FaIcon
  },
  mixins: [
    ApiMixin
  ],
  props: {
    year: Number
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/state/ecology/${this.$route.params.year}`
    },
    manualCompleteSections () {
      return {
        plantProtection: false,
        soilHealth: false,
        water: false
      }
    },
    loadingIcon () { return faSync }
  },
  methods: {
    onFinish (route) {
      const url = `${route}`
      this.data.finished[route] = !this.data.finished[route]
      if (this.data.finished[route]) {
        this.api.post(url)
      } else {
        this.api.delete(url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-indicator-block {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
}
</style>
